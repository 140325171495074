<template>
    <CForm ref="form" class="needs-validation"   >
        <CSelect
            label="Ürün"
            horizontal
            placeholder="Seçiniz.."
            :options="activeProducts"
            :value.sync="data.productId"
        />
        <CSelect
            label="Alınacak Stok Kanalı"
            horizontal
            placeholder="Seçiniz.."
            :options="stockChannelNames"
            :value.sync="data.fromPosId"
        />
        <CSelect
            label="Taşıma Hareketi"
            horizontal
            placeholder="Seçiniz.."
            :options="activeInventoryMoves"
            :value.sync="data.moveId"
        />
        <CSelect
            label="Verilecek Stok Kanalı"
            horizontal
            placeholder="Seçiniz.."
            :options="stockChannelNames"
            :value.sync="data.toPosId"
        />
   </CForm>
</template>

<script>
   export default{
       name: "MoveLogForm",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       computed:{
            stockChannelNames: function() {
               let data = []
               this.$store.getters.activePos.map(r => data.push({value:r.id, label: r.name}))
               return data
            },
            activeProducts: function() {
               let data = []
               this.$store.getters.activeProducts.map(r => data.push({value:r.id, label: r.name}))
               return data
            },
            activeInventoryMoves: function(){
                let data = []
                this.$store.getters.activeInventoryMoves.map(r => data.push({value:r.id, label: r.name}))
                return data
            }
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
   }
</script>